import React from "react"

import Layout from "../../../components/layout"
import SEO from "../../../components/seo"

const AgriculturePage = () => (
  <Layout>
    <SEO title="Agriculture" description="" lang="en" meta={[]} />

    {/* Industrial Section */}
    <section className="africa-agricultural focus-page">
      <h2 className="text-center mb-3">Agriculture</h2>
      <div className="section-img" />
    </section>
  </Layout>
)

export default AgriculturePage
